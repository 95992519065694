import { Component } from '@angular/core';

@Component({
  selector: 'app-legal-layout',
  templateUrl: './legal-layout.component.html',
  styleUrl: './legal-layout.component.scss'
})
export class LegalLayoutComponent {

}
