import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AdminLayoutComponent } from '@app/layouts/admin-layout/admin-layout.component';
import { AuthLayoutComponent } from '@app/layouts/auth-layout/auth-layout.component';
import { authGuard } from './core/guards/auth.guard';
import { UserNavLayoutComponent } from './layouts/user-nav-layout/user-nav-layout.component';
import { PageNotFoundComponent } from './shared/pages/page-not-found/page-not-found.component';
import { CguLearnersComponent } from './shared/pages/legal/cgu-learners/cgu-learners.component';
import { LegalLayoutComponent } from './layouts/legal-layout/legal-layout.component';
import { CguTrainersComponent } from './shared/pages/legal/cgu-trainers/cgu-trainers.component';
import { CookiesComponent } from './shared/pages/legal/cookies/cookies.component';
import { TermsAndConditionsComponent } from './shared/pages/legal/terms-and-conditions/terms-and-conditions.component';

const routes: Routes = [
  {
    path: 'auth',
    component: AuthLayoutComponent,
    loadChildren: () =>
      import('@modules/auth/auth.module').then((m) => m.AuthModule),
  },
  {
    path: 'home',
    component: UserNavLayoutComponent,
    loadChildren: () =>
      import('@modules/home/home.module').then((m) => m.HomeModule),
    canActivate: [authGuard],
    canActivateChild: [authGuard],
  },
  {
    path: 'serious-game',
    component: UserNavLayoutComponent,
    loadChildren: () =>
      import('@modules/serious-game/serious-game.module').then(
        (m) => m.SeriousGameModule
      ),
    canActivate: [authGuard],
    canActivateChild: [authGuard],
  },
  {
    path: 'admin',
    component: AdminLayoutComponent,
    loadChildren: () =>
      import('@modules/admin/admin.module').then((m) => m.AdminModule),
    canActivate: [authGuard],
    canActivateChild: [authGuard],
  },
  {
    path: 'legal',
    component: LegalLayoutComponent,
    children: [
      {
        path: 'cgu-learners',
        component: CguLearnersComponent,
      },
      {
        path: 'cgu-trainers',
        component: CguTrainersComponent,
      },
      {
        path: 'cookies',
        component: CookiesComponent,
      },
      {
        path: 'terms-and-conditions',
        component: TermsAndConditionsComponent,
      },
    ],
  },

  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full',
  },

  //Wild Card Route for 404 request
  {
    path: '**',
    pathMatch: 'full',
    component: PageNotFoundComponent,
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      useHash: false,
      initialNavigation: 'enabledNonBlocking',
      onSameUrlNavigation: 'reload',
      scrollPositionRestoration: 'enabled',
      anchorScrolling: 'enabled',
      paramsInheritanceStrategy: 'always',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule { }
