{
  "name": "<Nom du jeu>",
  "subtitle": "<Sous-titre du jeu>",
  "introduction": "<Introduction du jeu>",
  "skills": [
    "<Badge de skill>",
    "<Badge de skill>"
  ],
  "mascot_text": "<Texte de la mascotte>",
  "mascot_url": "<URL de l'image de la mascotte>",
  "background_url": "<URL de l'image de fond>",
  "card_background_url": "<URL de l'image de fond de la carte>",
  "story_background_url": "<URL de l'image de fond de l'histoire>",
  "progress_background_url": "<URL de l'image de fond de la progression>",
  "ranking_background_url": "<URL de l'image de fond du classement>",
  "profiling_level_icon_url": "<URL de l'image de l'icône de niveau de profil>",
  "story_level_icon_url": "<URL de l'image de l'icône de niveau d'histoire>",
  "question_level_icon_url": "<URL de l'image de l'icône de niveau de question>",
  "locked_level_icon_url": "<URL de l'image de l'icône de niveau verrouillé>",
  "xp_reward": 50,
  "isDarkTheme": true,
  "primary_color": "[HEX_COLOR]",
  "accent_color": "[HEX_COLOR]",
  "warn_color": "[HEX_COLOR]",
  "chapters": [
    {
      "title": "<Titre du chapitre>",
      "description": "<Description du chapitre>",
      "start_after": "[0-9]m[0-9]w[0-9]d",
      "mascot_text": "<Texte de la mascotte>",
      "mascot_url": "<URL de l'image de la mascotte>",
      "background_url": "<URL de l'image de fond>",
      "card_background_url": "<URL de l'image de fond de la carte>",
      "xp_reward": 50,
      "units": [
        {
          "name": "<Nom de l'unité>",
          "pdf_url": "<URL du PDF de cours>",
          "mascot_url": "<URL de l'image de la mascotte>",
          "xp_reward": 50,
          "levels": [
            {
              "name": "<Nom du level>",
              "mascot_text": "<Texte de la mascotte>",
              "mascot_url": "<URL de l'image de la mascotte>",
              "background_url": "<URL de l'image de fond>",
              "xp_reward": 50,
              "type": "LEARNING | STORY_TELLING | PROFILE_ANALYSIS",
              "steps": [
                {
                  "title": "<Titre de l'étape>",
                  "content": "<Contenu de l'étape>",
                  "mascot_text": "<Texte de la mascotte>",
                  "mascot_url": "<URL de l'image de la mascotte>",
                  "background_url": "<URL de l'image de fond>",
                  "xp_reward": 50,
                  "type": "question | story",
                  "questions": [
                    {
                      "wording": "Test Question",
                      "configuration": {},
                      "type": "qcm | sorting | matching | fill_in_the_blank | qcm_profiling",
                      "options": [
                        {
                          "value": "<Valeur de l'option>",
                          "label": "<Label de l'option>"
                        },
                        {
                          "value": "<Valeur de l'option>",
                          "label": "<Label de l'option>"
                        }
                      ],
                      "expected_answer": [
                        {
                          "value": "<Valeur de l'option>"
                        },
                        {
                          "value": "<Valeur de l'option>"
                        }
                      ],
                      "explanation": "<Explication de la question>",
                      "psychological_profile": "default"
                    },
                    {
                      "title": "<Titre de l'histoire>",
                      "content": "<Contenu de l'histoire>",
                      "psychological_profile": "default"
                    }
                  ]
                }
              ]
            }
          ]
        }
      ]
    }
  ]
}
