<mat-toolbar class="toolbar-user-mobile mat-toolbar-white flex flex-row justify-between">
  <!-- Logo -->
  <a href="/admin/home" class="flex flex-row items-center">
    <img src="assets/logo/fyyyre-tmp-logo-short.png" alt="" width="50" height="50"
      class="p-2 flex justify-center items-center rounded-lg" />

    <h1 class="text-3xl font-bold tracking-tight">Fyyyre</h1>
  </a>

  <!-- Logo -->

  <!-- Menu Burger for MobileView -->
  <div class="flex items-center justify-end lg:hidden">
    <div class="flex items-center">
      <button (click)="toggleMenu()" [matMenuTriggerFor]="menu"
        [ngClass]="{ active: menuOpenedState, 'not-active': !menuOpenedState }"
        class="btn not-active sm:scale-50 mobile-menu">
        <span class="dark:bg-white"></span>
        <span class="dark:bg-white"></span>
        <span class="dark:bg-white"></span>
      </button>
    </div>

    <mat-menu yPosition="below" [overlapTrigger]="false" #menu="matMenu" (closed)="toggleMenu()"
      class="mat-elevation-z0 nav-mobile translate-y-4">
      <mat-divider></mat-divider>
      <div class="toolbar-menu-burger">
        <button [routerLink]="'/admin/home'" mat-menu-item>
          <a [routerLinkActive]="'active-list-item-v3'" [routerLinkActiveOptions]="{ exact: true }">Accueil</a>
        </button>

        <button [routerLink]="['/admin/adventures']" mat-menu-item>
          <a [routerLinkActive]="'active-list-item-v3'" [routerLinkActiveOptions]="{ exact: true }">Mes aventures</a>
        </button>

        @if (isAdmin) {
        <button [routerLink]="['/admin/organisations']" mat-menu-item>
          <a [routerLinkActive]="'active-list-item-v3'" [routerLinkActiveOptions]="{ exact: true }">Organisations</a>
        </button>
        }

        <button [routerLink]="
            (user$ | async)?.currentGame
              ? ['/admin/serious-game/', ((user$ | async)?.currentGame)!.id]
              : null
          " mat-menu-item>
          <a [ngClass]="
              !(user$ | async)?.currentGame
                ? 'pointer-events-none'
                : 'cursor-pointer'
            " [routerLinkActive]="'active-list-item-v3'"
            [className]="(user$ | async)?.currentGame ? null : 'disabled'">
            <div class="flex flex-row items-center gap-2.5">
              @if ((user$ | async)?.currentGame?.mascot_url) {
              <img class="w-5" [src]="(user$ | async)?.currentGame?.mascot_url" />
              }

              {{ (user$ | async)?.currentGame?.name || "Sélectionner un jeu" }}
            </div>
          </a>
        </button>

        <mat-divider></mat-divider>

        @if (!isAdmin && user?.organisation) {
        <a mat-menu-item [routerLink]="['/admin', 'organisations', user.organisation.id]"
          [routerLinkActive]="'active-list-item-v3'">
          <div class="flex justify-start items-center gap-2">
            @if(user.organisation.logo) {
            <img [src]="user.organisation.logo" alt="logo" width="25" class="rounded" />
            }
            <a>
              {{ user.organisation.name ? user.organisation.name : "" }}
            </a>
          </div>
        </a>
        <mat-divider></mat-divider>
        }

        <button mat-menu-item [routerLinkActive]="'active-list-item-v3'" [routerLink]="'/admin/profile'"
          [queryParams]="{ tab: 1 }">
          <mat-icon svgIcon="setting-light" class="bg-transparent"></mat-icon>
          <a>Paramètres</a>
        </button>

        <button [routerLink]="'/admin/profile'" mat-menu-item>
          <div class="flex flex-row items-center gap-5">
            @if ((user$ | async)?.avatar) {
            <img class="user-circle-mobile" src="{{ (user$ | async)?.avatar }}" alt="image" crossorigin="anonymous"
              [routerLink]="'/admin/profile'" />
            } @else {
            <img class="user-circle-mobile p-1" src="assets/svg/iconly/user-bold.svg" alt="image"
              [routerLink]="'/admin/profile'" />
            }
            <a [routerLinkActive]="'active-list-item-v3'">Profil</a>
          </div>
        </button>

        <button (click)="signOut()" mat-menu-item>
          <mat-icon class="-translate-x-1" svgIcon="sign-out-light"></mat-icon>
          <a> Déconnexion </a>
        </button>
      </div>
    </mat-menu>
  </div>

  <!-- Menu Burger for MobileView -->

  <!-- Menu for DesktopView -->
  <div class="hidden lg:flex flex-row items-center gap-5">
    <a [routerLink]="'/admin/home'" [routerLinkActive]="'active-list-item-v3'"
      [routerLinkActiveOptions]="{ exact: true }">Accueil</a>

    <a [routerLink]="['/admin/adventures']" [routerLinkActive]="'active-list-item-v3'"
      [routerLinkActiveOptions]="{ exact: true }">Mes aventures</a>

    @if (isAdmin) {
    <a [routerLink]="['/admin', 'organisations']" [routerLinkActive]="'active-list-item-v3'"
      [routerLinkActiveOptions]="{ exact: true }">Organisations</a>
    }

    <a [ngClass]="
        !(user$ | async)?.currentGame ? 'pointer-events-none' : 'cursor-pointer'
      " [routerLinkActive]="'active-list-item-v3'" [routerLink]="
        (user$ | async)?.currentGame
          ? ['/admin/serious-game/', ((user$ | async)?.currentGame)!.id]
          : null
      " [className]="(user$ | async)?.currentGame ? null : 'disabled'">
      <div class="flex flex-row items-center gap-2.5 max-w-80">
        @if ((user$ | async)?.currentGame?.mascot_url) {
        <img class="w-5" [src]="(user$ | async)?.currentGame?.mascot_url" />
        }
        <div class="w-full text-ellipsis overflow-hidden whitespace-nowrap">
          {{ (user$ | async)?.currentGame?.name || "Aucun jeu selectionné" }}
        </div>
      </div>
    </a>

    @if (!isAdmin && user?.organisation) {
    <mat-divider [vertical]="true" class="h-5"></mat-divider>

    <a class="flex justify-center items-center gap-2" [routerLink]="['/admin', 'organisations', user.organisation.id]"
      [routerLinkActive]="'active-list-item-v3'">
      @if(user.organisation.logo) {
      <img [src]="user.organisation.logo" alt="logo" width="25" class="rounded" />
      }
      {{ user.organisation.name ? user.organisation.name : "" }}
    </a>
    }

    <mat-divider [vertical]="true" class="h-5"></mat-divider>

    @if (isAdmin) {
    <a (click)="openCreateOrUpdateInstanceDialog()">Créer un serious game</a>

    <mat-divider [vertical]="true" class="h-5"></mat-divider>
    <a [routerLink]="['/admin/fyyyre-admins']">Admins Fyyyre</a>
    }

    <a [routerLinkActive]="'active-list-item-v3'" [routerLink]="'/admin/profile'" [queryParams]="{ tab: 1 }">
      <mat-icon class="toolbar-icon" svgIcon="setting-light"></mat-icon>
    </a>

    <mat-divider [vertical]="true" class="h-5"></mat-divider>

    @if ((user$ | async)?.avatar) {
    <img class="user-circle" src="{{ (user$ | async)?.avatar }}" alt="image" crossorigin="anonymous"
      [routerLink]="'/admin/profile'" style="margin-left: -5px" />
    } @else {
    <img class="user-circle p-1" src="assets/svg/iconly/user-bold.svg" alt="image" [routerLink]="'/admin/profile'"
      style="margin-left: -5px" />
    }
  </div>
  <!-- Menu for DesktopView -->
</mat-toolbar>