import { LOCALE_ID, NgModule } from '@angular/core';
import {
  BrowserModule,
  provideClientHydration,
} from '@angular/platform-browser';



import { CommonModule, registerLocaleData } from '@angular/common';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { AuthLayoutComponent } from '@app/layouts/auth-layout/auth-layout.component';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CoreModule } from './core/core.module';
import { DataModule } from './data/data.module';
import { SidenavComponent } from './layouts/sidenav/sidenav.component';
import { ToolbarComponent } from './layouts/toolbar/toolbar.component';
import { UserNavLayoutComponent } from './layouts/user-nav-layout/user-nav-layout.component';
import { SharedModule } from './shared/shared.module';
import { FormlyModule } from '@ngx-formly/core';
import { ReactiveFormsModule } from '@angular/forms';
import { FormlyMaterialModule } from '@ngx-formly/material';
import { ToolbarUserComponent } from './layouts/toolbar-user/toolbar-user.component';
import { AdminLayoutComponent } from './layouts/admin-layout/admin-layout.component';
import { ToolbarAdminComponent } from './layouts/toolbar-admin/toolbar-admin.component';

import localeFr from '@angular/common/locales/fr';
import { provideCharts, withDefaultRegisterables } from 'ng2-charts';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { OrganizationsComponent } from '@modules/organization/pages/organizations/organizations.component';
import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';
import { LegalLayoutComponent } from './layouts/legal-layout/legal-layout.component';
//registerLocaleData(localeFr);

@NgModule({
  declarations: [
    AppComponent,
    SidenavComponent,
    ToolbarComponent,
    UserNavLayoutComponent,
    AuthLayoutComponent,
    ToolbarUserComponent,
    AdminLayoutComponent,
    ToolbarAdminComponent,
    OrganizationsComponent,
    LegalLayoutComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    CommonModule,
    SharedModule,
    DataModule,
    CoreModule,
    FormlyModule.forRoot(),
    ReactiveFormsModule,
    FormlyMaterialModule,
  ],
  providers: [
    provideClientHydration(),
    provideAnimationsAsync(),
    // { provide: LOCALE_ID, useValue: 'fr' },
    { provide: MAT_DATE_LOCALE, useValue: 'fr-FR' },
    provideCharts(withDefaultRegisterables())
  ],
  bootstrap: [AppComponent],
  exports: [
  ]
})
export class AppModule { }
