<section class="bg-color flex flex-col items-center h-screen" fxLayout="row">
  <!-- Auth Image -->
  <!-- <div class="flex justify-center basis-0 lg:basis-1/2"> -->
  <!--   <img src="/assets/logo/geeki-logo.png"/> -->
  <!-- <img src="/assets/logo/tigre_accueil_sansfond.png" />
  </div> -->
  <!-- / Auth Image -->
  <!-- Auth Content -->
  <div class="bg-color h-full w-screen mx-auto">
    <router-outlet></router-outlet>
  </div>
  <div class="flex flex-row w-full justify-center items-center gap-1 sm:gap-2 text-black dark:text-white mb-4">
    <a [routerLink]="['/legal/terms-and-conditions']" class="font-medium hover:text-indigo-500 hover:underline">Mentions
      légales</a>
    -
    <a [routerLink]="['/legal/cgu-trainers']" class="font-medium hover:text-indigo-500 hover:underline">CGU
      Formateur</a>
    -
    <a [routerLink]=" ['/legal/cgu-learners']" class="font-medium hover:text-indigo-500 hover:underline">CGU
      Apprenant</a>
  </div>
  <!-- / Auth Content -->
</section>
