import { Component } from '@angular/core';
import { NavigationEnd, Router } from "@angular/router";
import { filter } from "rxjs";
import { ThemeService } from '../../core/services/theme.service';

@Component({
  selector: 'app-admin-layout',
  templateUrl: './admin-layout.component.html',
  styleUrl: './admin-layout.component.scss'
})
export class AdminLayoutComponent {

  opened: boolean = true;
  showToolbar: boolean = true;

  constructor(private router: Router, public themeService: ThemeService) {
  }


  ngOnInit() {

    // Check for reset Default Theme ( Light / Dark )
    if (!this.router.url.startsWith("/serious-game/")) {
      this.themeService.resetThemeToDefault();
    }

    // Hide toolbar on levels page
    this.router.events.subscribe((event: any) => {

      if (event instanceof NavigationEnd) {

        // Check for reset Default Theme ( Light / Dark )
        if (!event.url.startsWith("/serious-game/")) {
          this.themeService.resetThemeToDefault();
        }

      }

    });
  }

}
