<app-toolbar-user *ngIf="showToolbar"></app-toolbar-user>

<mat-sidenav-container [ngClass]="!showToolbar ? 'h-dvh' : ''">

  <mat-sidenav-content>
    <div id="content-start"></div>
    <div class="router-content">
      <router-outlet></router-outlet>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>
